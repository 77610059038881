import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, RoutesRecognized } from '@angular/router';
import { pairwise, filter } from 'rxjs/operators';

@Injectable()
export class BusinessRuleService {

  

  Filter() {
    return {
      set: function (data) {
        //console.log(data);
        //console.log(JSON.stringify(data));
        localStorage.setItem('FilterStatus', JSON.stringify(data));
      },
      get: function () {
        //console.log(data);
        var data = localStorage.getItem('FilterStatus');
        if (data == null || data == "false") {
          return data;
        }
        else {
          return data;
        }
      }
    }
  }

}

class SearchFilters {
  SearchValue: string = '';
  filterId: number = 1;
  pageNumber: number = 1;
  pageSize: number = 500;
  filterList: any = [];
  tilefilterId: string = "";
  CustomViewModel: number = 0;
}
