import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
declare var $: any;

export interface CallCameraStatus {
  CallCameraData: (url: any) => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class CallCameraServiceService implements CallCameraStatus {
  // Observable string sources
  private componentMethodCallSource = new Subject<any>();

  // Observable string streams
  componentMethodCalled$ = this.componentMethodCallSource.asObservable();
  constructor() { }

  CallCameraData(url: any) {
    debugger
    const subject = new Subject<boolean>();
    var service = new Ajax.Service();
    var customerUrl = url;
    var options = new Ajax.Options(customerUrl);
    service.request(options, function (functionToCall) {
      debugger
      //isSave = true;
     // subject.asObservable();

    }).subscribe((data:any) => {
      console.log(data);
    })
   return subject.asObservable();
  }

  // Service message commands
  callComponentMethod() {
    this.componentMethodCallSource.next();
  }

}

module Ajax {
  export class Options {
    url: string;
    method: string;
    data: Object;
    constructor(url: string, method?: string, data?: Object) {
      this.url = url;
      this.method = method || "get";
      this.data = data || {};
    }
  
  }

  export class Service {
    
    public request = (options: Options, successCallback: Function, errorCallback?: Function): Observable<boolean> => {
      return new (Promise as any)((resolve) => {
        const subject = new Subject<boolean>();
        var that = this;
        $.ajax({
          url: options.url,
          type: options.method,
          crossOrigin: true,
          data: options.data,
          cache: false,
          crossDomain: true,
          success: function (d) {
            //successCallback(d);
            return (resolve);
          },
          error: function (d) {
            if (errorCallback) {
              errorCallback(d);
              return;
            }
            var errorTitle = "Error in (" + options.url + ")";
            var fullError = JSON.stringify(d);
            console.log(errorTitle);
            console.log(fullError);
            return (resolve);
          }
        });
        return subject;
      })
    }
    public get = (url: string, successCallback: Function, errorCallback?: Function): void => {
      this.request(new Options(url), successCallback, errorCallback);
    }
    public getWithDataInput = (url: string, data: Object, successCallback: Function, errorCallback?: Function): void => {
      this.request(new Options(url, "get", data), successCallback, errorCallback);
    }
    public post = (url: string, successCallback: Function, errorCallback?: Function): void => {
      this.request(new Options(url, "post"), successCallback, errorCallback);
    }
    public postWithData = (url: string, data: Object, successCallback: Function, errorCallback?: Function): void => {
      this.request(new Options(url, "post", data), successCallback, errorCallback);
    }


  }
}
