import { Injectable } from '@angular/core';
import { Query } from '@syncfusion/ej2-data';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
@Injectable({
  providedIn: 'root'
})

export class EjsMultiselectService {

  commonAttributeVal: any = []; // Create an array for common items from two attributeLists.
  slicedSelectedAttVal: any;
  allSelectedAttVal: any;
  allList: any = [];
  displayItemlength: number = 0;
  constructor() { }
  getAttributeVal(selectedAttributeVal, allAttributeList, filterText: any) {
    this.allSelectedAttVal = selectedAttributeVal;
    if (selectedAttributeVal == undefined || selectedAttributeVal == null) {
      selectedAttributeVal = [];
    }

    if (allAttributeList == undefined || allAttributeList == null) {
      allAttributeList = [];
    }
    this.allList = allAttributeList;
    if (filterText) {
      allAttributeList = allAttributeList.filter(x => x.attributeVal.toLowerCase().includes(filterText));
    }
    var dataReturn = {
      slicedSelectedAttVal: [],
      mainList: []
    }
    if (allAttributeList.length > 0) {
      if (selectedAttributeVal.length > 0) {
        this.commonAttributeVal = [];
        for (var item of selectedAttributeVal) {
          var filteredAttributeVal = allAttributeList.filter(x => x.attributeValueId == item);
          allAttributeList = allAttributeList.filter(x => x.attributeValueId != item);
          if (filteredAttributeVal.length > 0) {
            this.commonAttributeVal.push(...filteredAttributeVal);
          }
        }

        //[(value)]
        this.slicedSelectedAttVal = [];
        this.slicedSelectedAttVal = this.commonAttributeVal.map(x => x.attributeValueId).slice(0, 50);
        dataReturn.slicedSelectedAttVal=this.commonAttributeVal.map(x => x.attributeValueId).slice(0, 50);        
        if (this.commonAttributeVal.length > 50) {
          dataReturn.mainList = this.commonAttributeVal.slice(0, 50);
          this.displayItemlength = this.commonAttributeVal.slice(0, 50).length
          return dataReturn;
        }
        else {
          var sliceNum = 50 - this.commonAttributeVal.length;
          if (allAttributeList.length > 0) {
            var remainingItem = allAttributeList.map(x => x).slice(0, sliceNum);
            for (let item of remainingItem) {
              this.commonAttributeVal.push(item);
            }
          }
          dataReturn.mainList = this.commonAttributeVal;
          this.displayItemlength = this.commonAttributeVal.length
          return dataReturn;
        }
      }
      else {
        this.commonAttributeVal = [];
        dataReturn.mainList = allAttributeList.slice(0, 50);
        this.displayItemlength = allAttributeList.slice(0, 50).length;
        return dataReturn;
      }
    }
    else {
      dataReturn.mainList = [];
      return dataReturn;
    }
  }

  /*Bind the filter event on Add and Edit Attribute value in ejs-multiselect data [START]*/
  public onFiltering2: EmitType<any> = (e: FilteringEventArgs, SelectedAttVal, allAttributeList, filterTextValue) => {
   
    filterTextValue = e.text;
    let query = new Query();

    /*frame the query based on search string with filter type.*/
    query = e.text != '' ? query.where('attributeVal', 'startswith', filterTextValue, true) : query;

    /*pass the filter data source, filter query to updateData method.*/
    if (e.text != '') {
      return this.getAttributeVal(SelectedAttVal, allAttributeList,filterTextValue);
    } else {
      return this.getAttributeVal(SelectedAttVal, allAttributeList, '');
    }
  };
    /*Bind the filter event on Add and Edit Attribute value in ejs-multiselect data [END]*/

}
