import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { ConfirmationComponent } from './../pages/confirmation.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

export interface CheckChangesDeactivate {
  checkChanges: (totalchanges: number) => boolean | Observable<boolean>;
}

@Injectable()
export class AnyChangesCheckService implements CheckChangesDeactivate {
  
  modalRef: BsModalRef;
  //Check logged in userName
  private userName = new BehaviorSubject<string>('Martin');
  checkUser$ = this.userName.asObservable();

  checkUser(data: any) {
    
    this.userName.next(data);
  }

  constructor(private modalService: BsModalService) {
  };

  checkChanges(totalchanges:number) {
    debugger
      const subject = new Subject<boolean>();
      const modal = this.modalService.show(ConfirmationComponent, { 'class': 'modal-dialog-primary' });
    modal.content.subject = subject;
    modal.content.heading = 'Discard unsaved changes?';
    modal.content.message = " Please select 'Discard' to remove your changes.";
      return subject.asObservable();
  }
}
