import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { NumberDirective } from "../admin/receiving-tool/numbers-only.directive";
@NgModule({
  imports: [],
  declarations: [NumberDirective],
  exports: [NumberDirective]
})

export class NumberOnlyModule { }
