import { Injectable } from '@angular/core';

@Injectable()
export class imageExistenceService {
  constructor() { }
  async getWorkbookFromFile2(url) {
    return new Promise<boolean>((resolve, reject) => {
      var img = new Image();
      img.src = url;
      img.src = url;
      img.onload = () => {
        resolve(true);
      };

      img.onerror = () => {
        resolve(false);
      };
    });
  }
}
